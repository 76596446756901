import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import CTA from '../components/CTA'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import { Text, GallerySection, Content } from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'

export default ({ data }) => (
  <Layout header="light">
    <Helmet>
      <title>
        Patio Builders in Brisbane & the Sunshine Coast | Just Patios
      </title>
      <meta
        name="description"
        content="Just Patios has a team of professional patio builders servicing Brisbane and the Sunshine Coast. Our builders are experienced in buildng custom residential & commercial patios. Feel free to contact us today for a free design & quote."
      />
    </Helmet>

    <HomeHeader slant={false} height="75vh">
      <figure>
        <Img fluid={data.jpBackground.childImageSharp.fluid} />
      </figure>
      <div>
        <h2>Patio Builders & Designers</h2>
        <Text size="large">
          Looking at building a patio for your home? Our team at Just Patios is
          here to help you every step of the way.
        </Text>
        <Link to="/contact-us/">
          <Button>Contact us today</Button>
        </Link>
      </div>
      <FormContainer>
        <div>
          <Form title="Request Free Quote" showMessage={true} />
        </div>
      </FormContainer>
    </HomeHeader>

    <Content style={{ paddingTop: 100 }}>
      <h1>
        Brisbane & Sunshine Coast's <i>Trusted</i> Patio Builders
      </h1>

      <p>
        <span style={{ color: 'rgb(246, 159, 26)' }}>
          Just Patios is Open for Business.
        </span>
        {/* <span>
          <strong style={{ color: 'red' }}>
            Our office will be closing at 12:00pm on Friday the 18th of December
            2020 and will re-open at 8:30am on Tuesday the 5th of January 2021
          </strong>
        </span>
        <span>
          <strong style={{ color: 'green' }}>
            Our team would like to wish you and your family a Merry Christmas
            and a Happy, Safe & Prosperous New Year
          </strong>
        </span> */}
      </p>

      <p>
        Just Patios is aware of the Nations health issues currently and respect
        both your health and our staff. We are still able to operate with the
        most stringent health and hygiene controls as advised by the Federal
        Government. This enables us to quote and build without concern to you
        the customer. Correspondence for quotes and contracts via phone, email ,
        photos etc and a single onsite visit to confirm sizes (you don't have to
        be there). Builders are still able to construct your new project without
        anyone being home or use of your facilities apart from power/outdoor
        water. No access required in your home internally all can be done
        externally. Rest assured we are true professionals in the industry and
        with a healthy respect for your well being and our loyal and dedicated
        staff.
      </p>

      <h4>
        Just Patios is a team of <Link to="/about-us">professional</Link> patio
        builders in the Brisbane and Sunshine Coast regions. Having been in the
        construction industry for over 20 years, we offer an unmatched level of
        workmanship, providing customers with products and services that improve
        their quality of life. Our custom patios work perfectly for this
        purpose.
      </h4>
      <p>
        The weather in the Brisbane area can be extreme: it can be scorching one
        moment, and cool the next. No matter what the weather may be, an outdoor
        patio lets you enjoy your space comfortably.{' '}
      </p>

      <p>
        Outdoor patio living space is essential for enjoying a barbeque in the{' '}
        backyard or relaxing in summer out in the cool breeze with your
        favourite drink. Sunshine or rain, your family get together will always
        be great with one of our professionally designed and installed patio
        covers. Our patio designs are unlimited and one of our professional
        representatives can help you achieve your dream. We build all types of
        patios from flat/skillion to gables, flyover, and freestanding
        structures giving you protection from the weather. If you are concerned
        about lighting issues we can add light panels to your patio giving you
        that extra light you require.{' '}
      </p>

      <p>
        Should you need a reliable team to build and install a patio for you,
        look no further than the licensed builders at Just Patios. Our team
        offers you a variety of designs to choose from. The patios not only
        enhance your home; they also make your lifestyle more comfortable, and
        therefore, more enjoyable. Have a look at the options we have on offer
        and see which suits you best.{' '}
      </p>

      <Link to="/contact-us/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <AlternatingContent>
      <h2>
        Our <i>Patio</i> Range &amp; Designs
      </h2>
      <ContentCard
        image={data.offField.childImageSharp}
        title="Flyover Patio"
        to="/patio-builders-brisbane/flyover-patio"
        text="A flyover patio is a design which over the years has grown more popular with home owners. This design is elevated above your existing gutter line creating air flow and extra height. The added height gives you the feeling of space and allows extra natural lighting to filter through. This design can be built with both insulated and non-insulated roofing products. If you want the feeling of space and light this option is ideal and a great alternative."
        alt="Brisbane Patios"
        buttons={[
          {
            to: '/patio-builders-brisbane/flyover-patio',
            text: 'Learn more',
          },
        ]}
      />
      <ContentCard
        to="/patio-builders-brisbane/skillion-insulated"
        image={data.patiosBrisbane.childImageSharp}
        title="Flat / Skillion - Insulated Patio"
        text="This roofing material is substantially cooler in summer and is a step up from our non-insulated range. It offers a ceiling like appearance underside with the added benefit of being able to install lights & ceiling fans. This option is great if you are considering making the area into a room at a later date. It has all the options of the non-insulated roofing, with long spans & reducing the need for beams & rafters. This leaves you with a very low maintenance, high quality patio area with a range of colours to match your house.
        "
        alt="Insulated Patio Brisbane"
        buttons={[
          {
            to: '/patio-builders-brisbane/skillion-insulated',
            text: 'Learn more',
          },
        ]}
      />
      <ContentCard
        image={data.training.childImageSharp}
        to="/patio-builders-brisbane/skillion-non-insulated"
        title="Skillion - Non-Insulated Patio"
        text="This is one of our most popular designs creating extra space for your outside area. Long spanning Colourbond and Colourbond equivalent roofing eliminates the need for extra beams & rafters, and provides you the ceiling height you require. Our roofing comes in a range of colours and finishes to blend in with your existing gutters & fascia, complimenting your home."
        alt="Non-Insulated Skillion Patio Builders Brisbane"
        buttons={[
          {
            to: '/patio-builders-brisbane/skillion-non-insulated',
            text: 'Learn more',
          },
        ]}
      />
      <ContentCard
        image={data.accessories.childImageSharp}
        to="/patio-builders-brisbane/gable-patio"
        title="Gable Patio"
        text="There are a range of different designs and materials we can use to create the look you desire in a gable patio. Gable designs give you extra height, space & air flow – great for the summer months. Insulated & non-insulated roofing can be used depending on the look and style you prefer. These are very popular and a valuable addition to your home. Let us help you customise your new gable patio today so you can sit back and enjoy for years to come."
        alt="Gable Patios Brisbane"
        buttons={[
          {
            to: '/patio-builders-brisbane/gable-patio',
            text: 'Learn more',
          },
        ]}
      />
    </AlternatingContent>

    <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
      <h3>Want to find our more?</h3>
      <p>
        Call 1300 284 531 or complete the enquiry form to contact us today and
        find out how we can help.
      </p>
      <Flex>
        <Button color={'#fff'} small={true} outline={true}>
          Contact Us
        </Button>
      </Flex>
    </Hero>

    <GallerySection>
      <h2>Some of Our Recent Projects</h2>
      <p>
        Here at Just Patios, we do more than just build patios. We create
        structures that fit your lifestyle. If you require a patio for your
        home, our professional and licensed builders ensure high-quality results
        that enhance the value of your property. Our team build insulated and
        non-insulated patios, completely customising each job to meet your
        unique home needs.
      </p>
      <p>
        We’ve hand-selected the best systems in Australia and use quality
        products backed with 15-year warranties. View photos from some of our
        most recent projects and discover for yourself what we can achieve for
        you.
      </p>
      <GalleryContainer>
        {data.gallery.edges.map(
          (image, i) =>
            image.node.childImageSharp && (
              <GalleryImage modal={image.node.childImageSharp.modal}>
                <Img fixed={image.node.childImageSharp.fixed} />
              </GalleryImage>
            )
        )}
      </GalleryContainer>
    </GallerySection>

    <FAQs
      flushTop={true}
      faqs={info}
      title={
        <>
          What sets <i>us</i> apart?
        </>
      }
      background={data.whyUs.childImageSharp.fluid.src}
    />

    <VideoContainer>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query GalleryQuery {
    jpBackground: file(relativePath: { eq: "patio-builders-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "gallery/patios/flyover/cover.png" }) {
      childImageSharp {
        fluid(toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(
      relativePath: { eq: "gallery/patios/insulated/cover.png" }
    ) {
      childImageSharp {
        fluid(toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(
      relativePath: { eq: "gallery/patios/non-insulated/cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "gallery/patios/gable/cover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "blurred-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
